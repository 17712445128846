import { useEffect, useRef } from 'react';

import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import {
  ClientTypeUtils,
  NotificationType,
  type TownhallLargeGroupNotification,
} from '../../types';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { uuidv4 } from '../../utils/common';
import { useClock } from '../Clock';
import { useIsLiveGamePlay } from '../Game/hooks';
import { InfoIcon } from '../icons/InfoIcon';
import { useNotificationDataSource } from '../Notification/Context';
import { type BaseNotificationProps } from '../Notification/type';
import { useNumOfParticipants } from '../Player';
import {
  useIsStreamSessionAliveOrAborted,
  useIsStreamSessionEnded,
} from '../Session';
import { useMyClientId, useMyClientType } from '../Venue';
import { useTownhallAPI, useTownhallInited } from './Provider';

export function LiveGameTownhallLargeGroupSwticher(): JSX.Element | null {
  const api = useTownhallAPI();
  const playersCount = useNumOfParticipants({
    filters: ['host:false', 'cohost:false', 'status:connected', 'staff:false'],
  });
  const isSessionLiveOrAborted = useIsStreamSessionAliveOrAborted();
  const [isSessionEnded, sessionEndAt] = useIsStreamSessionEnded();
  const threshold = useInstance(() =>
    getFeatureQueryParamNumber('townhall-live-large-group-threshold')
  );
  const isLargeGroup = playersCount >= threshold;
  const myClientId = useMyClientId();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const nds = useNotificationDataSource();
  const townhallInited = useTownhallInited();
  const triggered = useRef(false);
  const isLiveGame = useIsLiveGamePlay();
  const clock = useClock();
  const resetTimeoutMs = useInstance(
    () =>
      getFeatureQueryParamNumber(
        'townhall-live-large-group-reset-after-game-secs'
      ) * 1000
  );

  useEffect(() => {
    if (!townhallInited || !isLiveGame) return;
    if (api.mode === 'team' || !isLargeGroup) return;
    // don't swtich if game is already started.
    if (isSessionLiveOrAborted) return;
    // post-game phase, don't switch.
    if (isSessionEnded && clock.now() - sessionEndAt < resetTimeoutMs) return;
    if (triggered.current) return;
    triggered.current = true;
    if (isHost) {
      api.setNext({
        mode: 'team',
        countdownSec: 0,
        type: 'global',
        source: 'LargeGroupTrigger',
      });
    } else {
      nds.send({
        id: uuidv4(),
        toUserClientId: myClientId,
        type: NotificationType.TownhallLargeGroup,
        createdAt: Date.now(),
      });
    }
  }, [
    api,
    clock,
    isHost,
    isLargeGroup,
    isLiveGame,
    isSessionEnded,
    isSessionLiveOrAborted,
    myClientId,
    nds,
    resetTimeoutMs,
    sessionEndAt,
    townhallInited,
  ]);

  useEffect(() => {
    if (!isSessionEnded || !isHost || !triggered.current) return;
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => {
      api.log.info('reset large group trigger');
      triggered.current = false;
    }, resetTimeoutMs);
    return () => ctrl.clear();
  }, [api.log, isHost, isSessionEnded, resetTimeoutMs]);

  return null;
}

export function LargeGroupNotificationView(
  props: BaseNotificationProps & {
    notification: TownhallLargeGroupNotification;
  }
): JSX.Element {
  const notificationDataSource = useNotificationDataSource();

  const handleClose = () => {
    notificationDataSource.dismiss(props.notification.id);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      notificationDataSource.dismiss(props.notification.id);
    }, 5000);

    return () => {
      clearTimeout(id);
    };
  }, [notificationDataSource, props.notification.id]);

  return (
    <div
      className='
        w-166
        text-main-layer
        rounded-xl
        bg-gradient-to-tr from-yellow-start to-yellow-end
    '
    >
      <div className='flex flex-row w-full h-full items-center justify-center gap-9 px-5 py-4.5'>
        <InfoIcon className='w-7 h-7 fill-current' color='#1B1B1E' />

        <div className='text-sm font-bold w-100'>
          We’ve swapped to stadium seating to accommodate the larger group size.
          Mingle with your colleagues and the experience will start shortly!
        </div>

        <div className='ml-auto flex flex-col gap-2.5 justify-between items-center'>
          <button
            type='button'
            className='w-32 h-10 btn-secondary bg-secondary text-icon-gray'
            onClick={handleClose}
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
}
